import { CrmReturnValue } from 'common-module/core/types';
import { DateTime } from 'luxon';

export type ParseDateTimeInput = Date | DateTime | string | null | undefined;

export const parseDateTime = <Value extends ParseDateTimeInput>(
  value: Value,
): CrmReturnValue<Value, DateTime> => {
  if (!value) {
    return undefined as CrmReturnValue<Value, DateTime>;
  }

  if (value instanceof Date) {
    return DateTime.fromJSDate(value);
  }

  if (value instanceof DateTime) {
    return value;
  }

  try {
    if (typeof value === 'string') {
      return DateTime.fromISO(value);
    }

    return undefined as CrmReturnValue<Value, DateTime>;
  } catch {
    return undefined as CrmReturnValue<Value, DateTime>;
  }
};
